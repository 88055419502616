import React from "react";
// import '../../assets/css/Style.css';
// import $ from 'jquery';
import YoreRewardsCard from "../../components/global/YoreRewardsCard";
// import stepsMobScreen from '../../assets/images/blueScreen.png'


// import jquery from 'jquery';
// window.$ = window.jQuery=jquery;

// Create the function
// export function AddLibrary(urlOfTheLibrary) {
//   const script = document.createElement('script');
//   script.src = urlOfTheLibrary;
//   script.async = true;
//   document.body.appendChild(script);
// }
  

function MicroCredit() {
    return (
        <div className="micro-credit-wrap">
            {/* Section Start */}
            <div className="micro-credit-section-01">
                <div className="container">
                    <div className="y-card">

                        
                        <div className="row">
                            <div className="col-md-8 my-auto">
                                <h3 className="y-title yor-tx-blue mb-2">Because big change start with <span className="red-tx">small steps</span></h3>
                                <ul className="sml-step">
                                    <li><button className="step-lnk"><span>1</span></button></li>
                                    <li><button className="step-lnk"><span>2</span></button></li>
                                    <li className="active"><button className="step-lnk"><span>3</span></button></li>
                                    <li><button className="step-lnk"><span>4</span></button></li>
                                    <li><button className="step-lnk"><span>5</span></button></li>
                                </ul>
                                <h4 className="mcrd-step-title">Open the joint liability group creation section</h4>
                            </div>
                            <div className="col-md-4">
                                <div className="start-steps">
                                    <div className="steps-mob-screen">
                                        {/* <img src={stepsMobScreen} alt="cd-card" className="img-fluid" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                {/* <div className="container"> */}
                    {/* <div className="row g-0 justify-content-center"> */}
                        {/* start tab */}
                        {/* <div className="col-md-8 my-md-auto">
                            <ul className="mcrd-step-list" id="myTab" role="tablist">
                                <li role="presentation">
                                    <button className="nav-link active" id="NFC1-tab" data-bs-toggle="tab" data-bs-target="#NFC1-tab-pane" type="button" role="tab" aria-controls="NFC1-tab-pane" aria-selected="true"><span>1</span> Download app on your device</button>
                                </li>
                                <li role="presentation">
                                    <button className="nav-link" id="NFC2-tab" data-bs-toggle="tab" data-bs-target="#NFC2-tab-pane" type="button" role="tab" aria-controls="NFC2-tab-pane" aria-selected="false"><span>2</span> Visit the micro credit section within the app</button>
                                </li>
                                <li role="presentation">
                                    <button className="nav-link" id="NFC3-tab" data-bs-toggle="tab" data-bs-target="#NFC3-tab-pane" type="button" role="tab" aria-controls="NFC3-tab-pane" aria-selected="false"><span>3</span> Open the joint liability group creation section</button>
                                </li>
                                <li role="presentation">
                                    <button className="nav-link" id="NFC4-tab" data-bs-toggle="tab" data-bs-target="#NFC4-tab-pane" type="button" role="tab" aria-controls="NFC4-tab-pane" aria-selected="false"><span>4</span> Attend knowledge sharing camps</button>
                                </li>
                                <li role="presentation">
                                    <button className="nav-link" id="NFC5-tab" data-bs-toggle="tab" data-bs-target="#NFC5-tab-pane" type="button" role="tab" aria-controls="NFC5-tab-pane" aria-selected="false"><span>5</span> Repay the loan on time</button>
                                </li>
                            </ul>                            
                        </div> */}
                        {/* <div className="col-md-4">
                            <div className="start-steps">
                                <div className="steps-mob-screen">
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="NFC1-tab-pane" role="tabpanel" aria-labelledby="NFC1-tab" tabIndex="0">
                                        <div className="animi-icon-box">
                                            A
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="NFC2-tab-pane" role="tabpanel" aria-labelledby="NFC2-tab" tabIndex="0">
                                        <div className="animi-icon-box">
                                            B
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="NFC3-tab-pane" role="tabpanel" aria-labelledby="NFC3-tab" tabIndex="0">
                                        C
                                    </div>
                                    <div className="tab-pane fade" id="NFC4-tab-pane" role="tabpanel" aria-labelledby="NFC4-tab" tabIndex="0">
                                        d
                                    </div>
                                    <div className="tab-pane fade" id="NFC5-tab-pane" role="tabpanel" aria-labelledby="NFC5-tab" tabIndex="0">
                                        e
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* end tab */}

                    {/* </div> */}
                {/* </div> */}
            </div>
            {/* Section End */}
            {/* Section Start */}
            <div className="micro-credit-section-02">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="y-card">
                                <div className="row">
                                    <div className="col-md-9">
                                        <h3 className="y-title yor-tx-blue mb-4">Empowering Women,<br />Transforming Lives</h3>
                                        <div className="d-md-none d-block">
                                            <div className="mob-animi-box"></div>
                                        </div>
                                        <p className="txt-35 fw-500 lh-sm yor-tx-grey my-md-3 mob-w-75 mx-auto">Breaking Barriers, Uplifting Communities with Microcredit</p>
                                        {/* <button className="yor-btn yor-bg-blue yor-tx-white mt-3">Open Saving Account Now</button> */}
                                    </div>
                                    <div className="col-md-7 d-md-block d-none">
                                        <div style={{minHeight: 420}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Section End */}
            {/* Section Start */}
            <div className="micro-credit-section-03">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="y-card">
                                <div className="row">
                                    <div className="col-md-6 d-md-block d-none">
                                        <div className="expand-box"></div>
                                    </div>
                                    <div className="col-md-6 my-md-auto">
                                        <h3 className="y-title yor-tx-blue mb-md-4">Expand your horizons with our knowledge sharing camp</h3>
                                        <div className="d-md-none d-block">
                                            <div className="expand-box"></div>
                                        </div>
                                        <button className="yor-btn yor-bg-blue yor-tx-white mt-3 px-5 py-3">Check Now</button>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Section End */}
            <YoreRewardsCard />            
        </div>
    );
  }
  
  export default MicroCredit;
