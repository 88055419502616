import {React} from "react";
// import '../../assets/css/Style.css';
// import $ from 'jquery';
import YoreRewardsCard from "../../components/global/YoreRewardsCard";

import Rupee from '../../assets/images/rupee.svg';
// import jquery from 'jquery';
// window.$ = window.jQuery=jquery;

// Create the function
// export function AddLibrary(urlOfTheLibrary) {
//   const script = document.createElement('script');
//   script.src = urlOfTheLibrary;
//   script.async = true;
//   document.body.appendChild(script);
// }
  

function PersonalCredit() {

    return (
        <div className="personal-credit-wrap">
            {/* Section Start */}
            <div className="personal-credit-section-01">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mb-5">
                            <div className="y-card">
                                <div className="row">
                                    <div className="col-md-11">
                                        <h2 className="y-title yor-tx-blue">We trust yore financial health, not your credit history</h2>                                        
                                    </div>
                                    <div className="col-md-8">
                                        <p className="txt-35 fw-500 yor-tx-grey"><span className="blue-tx">YORE</span> Interest Rate Decreases as Your Financial Health Improves</p>

                                        <div className="d-md-block d-none">
                                            <div style={{minHeight:90}}></div>
                                        </div>                                        
                                        <div className="d-md-none d-block">
                                            <div className="mob-animi-box"></div>
                                        </div>

                                        <p className="y-para fw-bold dark-blue-tx">Get a rate of interest as low as 8%* based on financial health</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Section End */}
            {/* Section Start */}
            <div className="personal-credit-section-02">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-10 mx-auto text-center mb-md-5 mb-4">
                            <h3 className="y-title yor-tx-blue mb-2">Accelerate <span className="blue-tx">yore</span> loan repayment game</h3>
                            <h4 className="y-title yor-tx-grey">With our flexible Pre-EMI facility</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 order-md-1 order-2">
                            <div className="y-card-sm-off">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className=" left-pre-emi-box  mb-4">
                                            <div className=" d-flex align-items-center gap-3">
                                                <div className=" round-chack chack-outer1 d-flex align-items-center justify-content-center">
                                                    <div className="center-chack chack1"></div>
                                                </div>
                                                <div>
                                                    <h5 className=" txt-25 yor-tx-blue">3 Month</h5>
                                                    <p className="txt-25 yor-tx-grey">No Interest </p>
                                                </div>
                                            </div>
                                            <strong className=" txt-25 yor-tx-blue m-0">₹1,000</strong>
                                        </div>
                                        <div className=" left-pre-emi-box  mb-4">
                                            <div className=" d-flex align-items-center gap-3">
                                                <div className=" round-chack chack-outer2 d-flex align-items-center justify-content-center">
                                                    <div className="center-chack chack2"></div>
                                                </div>
                                                <div>
                                                    <h5 className=" txt-25 yor-tx-blue">6 Month</h5>
                                                    <p className="txt-25 yor-tx-grey">No Interest </p>
                                                </div>
                                            </div>
                                            <strong className=" txt-25 yor-tx-blue m-0">₹6,000</strong>
                                        </div>
                                        <div className=" left-pre-emi-box">
                                            <div className=" d-flex align-items-center gap-3">
                                                <div className=" round-chack chack-outer3 d-flex align-items-center justify-content-center">
                                                    <div className="center-chack chack3"></div>
                                                </div>
                                                <div>
                                                    <h5 className=" txt-25 yor-tx-blue">12 Month</h5>
                                                    <p className="txt-25 yor-tx-grey">No Interest </p>
                                                </div>
                                            </div>
                                            <strong className=" txt-25 yor-tx-blue m-0">₹12,000</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 order-md-2 order-1">
                            <div className="y-card-sm">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="y-para dark-blue-tx py-md-4 px-4 my-md-5 lh-sm">
                                            The pre-EMI ( interest only ) facility allows borrowers to pay only the interest component of the loan amount for a specified period, typically up to 12 months.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* Section End */}
            {/* Section Start */}
            <div className="personal-credit-section-03">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="y-card">
                                <div className="row align-items-center">
                                    <div className="col-md-10 mx-auto text-center">
                                        <h2 className="y-title yor-tx-blue mb-2">Want to pay on <span className="blue-tx">yore</span> terms?</h2>
                                        {/* <h5 className="y-title">Get banking at lightning speed</h5> */}
                                        
                                        {/* <button className="yor-btn yor-bg-blue yor-tx-white mt-3">Open Saving Account Now</button> */}
                                    </div>
                                    <div className="col-md-6 mx-auto text-center">
                                        <p className="txt-35 fw-500 yor-tx-grey">Try yore Pre-Payment and Part Payment options</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Section End */}
            {/* Section Start */}
            <div className="personal-credit-section-04">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-10 mx-auto text-center mb-5">
                            <h3 className="y-title yor-tx-blue mb-2">EMI adjustment option</h3>
                            <h4 className="y-title yor-tx-grey">Now you can speed up or slow down your payments as per <span className="blue-tx">yore</span> convenience . <span className="blue-tx">It's your loan, your way!</span></h4>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="y-card-sm">
                                <button className="int-lnk">Before</button>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="Before-amount mb-3 ">
                                            <div className="left-renge">
                                                <h5>Loan Amount</h5>
                                                <div className="range">
                                                    <input type="range" min="0" max="100" step="0" />
                                                </div>
                                            </div>
                                            <div className="left-amount">
                                                <form action="#">
                                                    <div className="in-amount position-relative">
                                                        <input type="text" value="1,40,000" />
                                                        <img src={Rupee} alt="Rupee" className=" img-fluid" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="Before-amount mb-3">
                                            <div className="left-renge">
                                                <h5>EMI Amount</h5>
                                                <div className="range">
                                                    <input type="range" min="0" max="100" step="0" />
                                                </div>
                                            </div>
                                            <div className="left-amount">
                                                <form action="#">
                                                    <div className="in-amount position-relative">
                                                        <input type="text" value="8,630" />
                                                        <img src={Rupee} alt="Rupee" className=" img-fluid" />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="Before-amount mb-3">
                                            <div className="left-renge">
                                                <h5>Loan Amount</h5>
                                                <div className="range">
                                                    <input type="range" min="0" max="100" step="0" />
                                                </div>
                                            </div>
                                            <div className="left-amount">
                                                <form action="#">
                                                    <div className="in-amount position-relative">
                                                        <input type="text" value="12" />
                                                        <h3>Months</h3>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="output-amo">
                                            <div>
                                                <p>Monthly EMI</p>
                                                <h5>₹8,630</h5>
                                            </div>
                                            <div>
                                                <p>Principal Amount</p>
                                                <h5>₹1,00,000</h5>
                                            </div>
                                            <div>
                                                <p>Total Interest </p>
                                                <h5>₹3,556 </h5>
                                            </div>
                                            <div>
                                                <p>Total Amount</p>
                                                <h5>₹1,03,556</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="y-card-sm">
                                <button className="int-lnk">After</button>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="right-after">
                                            <div className="after-top mb-4">
                                                <div className="left-amount">
                                                    <form action="#">
                                                        <div className="in-amount position-relative">
                                                            <input type="text" value="1,00,000"/>
                                                            <img src={Rupee} alt="Rupee" className=" img-fluid" />
                                                        </div>
                                                    </form>
                                                </div>
                                                <div>
                                                    <h5 className=" yor-tx-blue fw-bold mb-1">Loan Amount</h5>
                                                    <p className=" txt-14 yor-tx-grey mb-0 fw-500">Loan amount is fixed</p>
                                                </div>
                                            </div>
                                            <div className="Before-amount mb-3">
                                                <div className="left-renge">
                                                    <h5>EMI Amount</h5>
                                                    <div className="range">
                                                        <input type="range" min="0" max="100" step="0" />
                                                    </div>
                                                </div>
                                                <div className="left-amount">
                                                    <form action="#">
                                                        <div className="in-amount position-relative">
                                                            <input type="text" value="4,455" />
                                                            <img src={Rupee} alt="Rupee" className=" img-fluid" />
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="Before-amount mb-3">
                                                <div className="left-renge">
                                                    <h5>Loan Amount</h5>
                                                    <div className="range">
                                                        <input type="range" min="0" max="100" step="0" />
                                                    </div>
                                                </div>
                                                <div className="left-amount">
                                                    <form action="#">
                                                        <div className="in-amount position-relative">
                                                            <input type="text" value="24" />
                                                            <h3>Months</h3>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="output-amo">
                                                <div>
                                                    <p>Monthly EMI</p>
                                                    <h5>₹4,455</h5>
                                                </div>
                                                <div>
                                                    <p>Principal Amount</p>
                                                    <h5>₹1,00,000</h5>
                                                </div>
                                                <div>
                                                    <p>Total Interest </p>
                                                    <h5>₹6,911</h5>
                                                </div>
                                                <div>
                                                    <p>Total Amount</p>
                                                    <h5>₹1,06,911</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* Section End */}
            <YoreRewardsCard />
        </div>
    );
  }
  
export default PersonalCredit;
